<template>
  <v-tabs grow show-arrows continuous slider-size="4" centered icons-and-text>
    <v-tabs-slider color="primary"></v-tabs-slider>
    <v-tab v-on:change="filterOrders(0)">
      {{$t('order-tabs.all-orders-tab')}}
      <v-icon color="indigo">mdi-progress-download</v-icon>
    </v-tab>
    <v-tab v-on:change="filterOrders(1)">
      {{$t('order-tabs.new-orders-tab')}}
      <v-icon color="red">mdi-progress-alert</v-icon>
    </v-tab>
    <v-tab v-on:change="filterOrders(2)">
      {{$t('order-tabs.inprogress-orders-tab')}}
      <v-icon color="orange">mdi-progress-clock</v-icon>
    </v-tab>
    <v-tab v-on:change="filterOrders(3)">
      {{$t('order-tabs.debt-orders-tab')}}
      <v-icon color="blue">mdi-progress-upload</v-icon>
    </v-tab>
    <v-tab v-on:change="filterOrders(4)">
      {{$t('order-tabs.paid-orders-tab')}}
      <v-icon color="green">mdi-progress-check</v-icon>
    </v-tab>

    <v-tab-item>
      <TableBody tabId=0 :orders="filteredOrders" :loading="loading"
                 v-on:apply-filters="applyFilters($event)" v-on:reset-filters="resetFilters()"></TableBody>
    </v-tab-item>

    <v-tab-item>
      <TableBody tabId=1 :orders="filteredOrders" :loading="loading"></TableBody>
    </v-tab-item>

    <v-tab-item>
      <TableBody tabId=2 :orders="filteredOrders" :loading="loading"></TableBody>
    </v-tab-item>

    <v-tab-item>
      <TableBody tabId=3 :orders="filteredOrders" :loading="loading"></TableBody>
    </v-tab-item>

    <v-tab-item>
      <TableBody tabId=4 :orders="filteredOrders" :loading="loading"></TableBody>
    </v-tab-item>
  </v-tabs>

</template>

<script>
  import TableBody from "./TableBody";
  import {mapState} from 'pinia'
  import {useOrders} from '../../stores/ordersStore'
  import {useAgencies} from "../../stores/agenciesStore";

  export default {
    name: "Orders",
    components: {TableBody},
    data() {
      return {
        filteredOrders: [],
      }
    },
    computed: {
      ...mapState(useOrders, ['orders', 'loading']),
      ...mapState(useAgencies, ['agencies']),
    },
    methods: {
      async updateNeededCheck() {
        if (!this.orders.length || !this.agencies.length) {
          const ordersStore = useOrders()
          let response = await ordersStore.getOrdersAsync()
          this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
          this.filterOrders(0)
        } if(this.orders.length) {
          this.filterOrders(0)
        }
      },
      filterOrders(tabId) {
        if (tabId < 1) {
          this.filteredOrders = this.orders;
        }
        if (tabId > 0) {
          this.filteredOrders = this.orders.filter(e => e.status.statusId == tabId)
        }
      },
      resetFilters() {
        this.filteredOrders = this.orders;
      },
      applyFilters(filters) {
        let startDate = new Date(filters.dateRange[0]);
        let endDate = new Date(filters.dateRange[1]);
        //when start date later than end date -> set dates visa versa
        if (startDate.getTime() > endDate.getTime()) {
          let tmp = endDate;
          endDate = startDate;
          startDate = tmp;
        }
        let agencyId = filters.agencyId;
        if (agencyId === '') {
          this.filteredOrders = this.orders.filter(e => new Date(e.orderDate).getTime() >= startDate.getTime() && new Date(e.orderDate).getTime() <= endDate.getTime());
          console.log('case1')
        }
        if (filters.dateRange.length < 1) {
          this.filteredOrders = this.orders.filter(e => e.agency.agencyId == agencyId);
          console.log('case2')
        }
        if (filters.dateRange.length > 0 && agencyId != '') {
          this.filteredOrders = this.orders.filter(e => new Date(e.orderDate).getTime() >= startDate.getTime() && new Date(e.orderDate).getTime() <= endDate.getTime() &&
            e.agency.agencyId == agencyId);
          console.log('range' + filters.dateRange)
          console.log('case3')
        }
      },
    },
    created() {
      this.updateNeededCheck()
    },
  }
</script>

<style>

</style>
