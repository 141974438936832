<template>
  <v-container fluid>
    <base-material-card
      class="px-5 py-3" color="primary">
      <template v-slot:heading>
        <v-row class="ma-auto">
          <v-col md="8" sm="8">
            <h3 v-if="tabId==0">{{$t('tables.all-orders')}}
              <v-badge offset-x="-5" offset-y="-5" color="indigo"
                       :content="orders.length?orders.length:'0'"></v-badge>
            </h3>
            <h3 v-if="tabId==1">{{$t('tables.new-orders')}}
              <v-badge offset-x="-5" offset-y="-5" color="red"
                       :content="orders.length?orders.length:'0'"></v-badge>
            </h3>
            <h3 v-if="tabId==2">{{$t('tables.in-progress-orders')}}
              <v-badge offset-x="-5" offset-y="-5" color="orange"
                       :content="orders.length?orders.length:'0'"></v-badge>
            </h3>
            <h3 v-if="tabId==3">{{$t('tables.debt-orders')}}
              <v-badge offset-x="-5" offset-y="-5" color="blue"
                       :content="orders.length?orders.length:'0'"></v-badge>
            </h3>
            <h3 v-if="tabId==4">{{$t('tables.paid-orders')}}
              <v-badge offset-x="-5" offset-y="-5" color="green darken-3"
                       :content="orders.length?orders.length:'0'"></v-badge>
            </h3>
          </v-col>
          <v-col md="4" sm="4">
            <CreateObjectButton :object="object"></CreateObjectButton>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-auto">
          <v-col md="8" sm="8">
            <h4 v-if="tabId!=4">{{$t('tables.debt')}}: {{countTotalDebt().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}
              {{$t('currencies.uah')}}</h4>
          </v-col>
        </v-row>
      </template>

      <v-card-title>
        <v-row class="ma-auto">
          <v-col cols="12" md="4" sm="6">
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details>
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="8" sm="6">
            <v-expansion-panels v-model="panel" v-if="tabId==0" popout hover tile>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-icon color="primary" left>mdi-filter-outline</v-icon>
                        {{$t('filters.filters')}}
                      </v-col>
                      <v-col cols="8" class="text--primary">
                        <v-fade-transition leave-absolute>
                          <span v-if="open" key="0">{{$t('filters.orders')}}: {{orders.length}} <v-icon color="primary">mdi-circle-small</v-icon> {{$t('filters.revenue')}}: {{calculateRevenue}} <v-icon
                            color="primary">mdi-circle-small</v-icon> {{$t('filters.debt')}}: {{countTotalDebt().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</span>
                          <span v-else key="1"></span>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox
                        v-model="checkboxDate"
                        :label="$t('filters.date')" v-on:change="fieldBlock">
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="dateRange"
                        persistent
                        width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on:blur="populateFilters(dateRange,'dateRange')"
                            :disabled="disabledDate"
                            v-model="dateRangeText"
                            :label="$t('filters.select-date-range')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on">
                          </v-text-field>
                        </template>
                        <v-date-picker :first-day-of-week="datePickerProps.firstDayOfWeek"
                                       v-model="dateRange" scrollable range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            {{$t('buttons.cancel')}}
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(dateRange)">
                            {{$t('buttons.ok')}}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox
                        v-model="checkboxAgency"
                        :label="$t('filters.agency')" v-on:change="fieldBlock">
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-autocomplete :items="agencies" :label="$t('filters.select-agency')" item-text="agencyName"
                                      clearable item-value="agencyId" v-model="agencyId" :disabled="disabledAgency"
                                      v-on:change="populateFilters(agencyId,'agencyId')" prepend-icon="mdi-domain">
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-btn :disabled="disabledButton" block color="primary"
                             v-on:click="$emit('apply-filters',filters)">
                        <v-icon left dark> mdi-filter-plus</v-icon>
                        {{$t('buttons.apply-filters')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn :disabled="disabledButton" block color="secondary" v-on:click="resetFilters">
                        <v-icon left dark> mdi-filter-off</v-icon>
                        {{$t('buttons.reset-filters')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        dense
        single-expand
        item-key="orderId"
        loader-height="5"
        :loading="loading"
        :loading-text="$t('loadings.loading-orders')"
        :no-data-text="$t('loadings.no-orders-found')"
        multi-sort
        :headers="headers"
        :items="orders"
        :search="search"
        :expanded.sync="expanded"
        show-expand
        :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
        <template v-slot:item.status.statusName="{ item }">
          <v-chip :color="item.status.statusColor" dark small label style="width: 75px;justify-content: center">
            {{ item.status.statusName }}
          </v-chip>
        </template>

        <template v-slot:item.agency.agencyName="{ item }">
          <router-link :to="{ path: '/agency/' + item.agency.agencyId}" class="no-underline-link">
            {{item.agency.agencyName}}
          </router-link>
        </template>

        <template v-slot:item.statusIcon="{ item }">
          <v-icon color="orange"
                  v-if="(new Date(item.finishTime).getDate()-new Date().getDate())<=2&&(new Date(item.finishTime)>new Date())&&(item.status.statusName==='New'||item.status.statusName==='In progress')">
            mdi-circle-medium
          </v-icon>
          <v-icon color="red"
                  v-if="new Date(item.finishTime)<new Date()&&(item.status.statusName==='New'||item.status.statusName==='In progress')">
            mdi-circle-medium
          </v-icon>
        </template>

        <template v-slot:item.orderCode="{ item }">
          <router-link :to="{ path: '/order/' + item.orderId}" class="no-underline-link">
            {{item.orderCode}}
          </router-link>
        </template>
        <template v-slot:item.orderDate="{ item }">
          <span :key="parseDate(item.orderDate)">{{parseDate(item.orderDate)}}</span>
        </template>
        <template v-slot:item.finishTime="{ item }">
          <span :key="parseTime(item.finishTime)">{{parseTime(item.finishTime)}}</span>
        </template>
        <template v-slot:item.langPair="{ item }">
          <span :key="parseLangPairs(item.langPair)">{{finalString}}</span>
        </template>
        <template v-slot:item.totalCost="{ item }">
          <span>{{item.totalCost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</span>
        </template>
        <template v-slot:item.description="{ item }">
          <span v-if="item.description.length>250">{{item.description.substr(0,250)}} ...</span>
          <span v-if="item.description.length<=250">{{item.description}}</span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length/3">
            <v-simple-table dense>
              <tr>
                <td>
                  {{$t('table-expand.manager')}}:
                </td>
                <td>
                  <template v-if="item.manager!==null"><h5>{{item.manager.surname}}</h5></template>
                </td>
                <td>
                  {{$t('table-expand.incoming-no')}}:
                </td>
                <td>
                  <h5>{{item.incomingNumber}}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('table-expand.topic')}}:
                </td>
                <td>
                  <h5>{{parseTopic(item.topic)}}</h5>
                </td>
                <td>
                  {{$t('table-expand.order-type')}}:
                </td>
                <td>
                  <h5>{{item.orderType.orderType}}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('table-expand.total-cost')}}:
                </td>
                <td>
                  <h5>{{item.totalCost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</h5>
                </td>
                <td>
                  {{$t('table-expand.price-per-page')}}:
                </td>
                <td>
                  <h5>{{item.pricePerPage}}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('table-expand.pages')}}:
                </td>
                <td>
                  <h5>{{item.pageCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</h5>
                </td>
                <td>
                  {{$t('table-expand.symbols')}}:
                </td>
                <td>
                  <h5>{{item.symbolCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</h5>
                </td>
              </tr>
              <tr v-if="item.payer!==null">
                <td>
                  {{$t('table-expand.payer')}}:
                </td>
                <td>
                  <template v-if="item.payer!==null"><h5>{{ item.payer.payerName }}</h5></template>
                </td>
                <td>
                  {{$t('table-expand.payment-date')}}:
                </td>
                <td>
                  <h5>{{parseDate(item.paymentDate)}}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('table-expand.created-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.createdTime) }}</h5>
                </td>
                <td>
                  {{$t('table-expand.modified-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.modifiedTime) }}</h5>
                </td>
              </tr>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";
  import {mapState} from 'pinia'
  import {useAgencies} from "../../stores/agenciesStore";

  let moment = require('moment');

  export default {
    props: ['tabId', 'orders', 'loading'],
    name: "TableBody",
    components: {CreateObjectButton},
    data() {
      return {
        panel: [],
        filters: {
          dateRange: [],
          agencyId: ''
        },
        ordersBackup: [],
        disabledButton: true,
        disabledDate: true,
        disabledAgency: true,
        checkboxDate: false,
        checkboxAgency: false,
        modal: false,
        agencyId: '',
        datePickerProps: {
          firstDayOfWeek: 1
        },
        dateRange: [],
        expanded: [],
        object: 'Order',
        filteredOrders: [],
        sort: true,
        search: '',
        headers: [
          {
            sortable: false,
            value: 'statusIcon',
            width: 2
          },
          {
            text: this.$t('tables.order-code'),
            align: 'start',
            sortable: false,
            value: 'orderCode',
            class: "primary--text",
            divider: true
          },
          {text: this.$t('tables.description'), value: 'description', class: "primary--text", sortable: true},
          {text: this.$t('tables.agency'), value: 'agency.agencyName', class: "primary--text", sortable: true},
          {text: this.$t('tables.order-date'), value: 'orderDate', class: "primary--text", sortable: true},
          {text: this.$t('tables.finish-time'), value: 'finishTime', class: "primary--text", sortable: true},
          {text: this.$t('tables.total-cost'), value: 'totalCost', class: "primary--text", sortable: true},
          {text: this.$t('tables.short-pair'), value: 'langPair', class: "primary--text", sortable: true},
          {
            text: this.$t('tables.incomingNumber'),
            value: 'incomingNumber',
            class: "primary--text",
            sortable: true,
            align: ' d-none'
          },
          {text: this.$t('tables.status'), value: 'status.statusName', class: "primary--text", sortable: true},
        ],
      }
    },
    methods: {
      updateNeededCheck() {
        if (!this.agencies.length) {
          const agencyStore = useAgencies()
          agencyStore.getAgencyList()
        }
      },
      resetFilters() {
        this.panel = [];
        this.$emit('reset-filters');
        this.checkboxAgency = false;
        this.checkboxDate = false;
        this.agencyId = '';
        this.dateRange = [];
        this.panel = [];
      },
      countTotalDebt() {
        if (this.tabId == 0) {
          return this.orders.filter(e => e.status.statusName === 'Finished')
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0);
        } else {
          return this.orders.filter(e => e.status.statusName !== 'Paid' && e.status.statusName !== 'Cancelled')
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0);
        }
      },
      parseTopic(topicArray) {
        this.finalString = ''
        let i;
        if (topicArray.length !== 0) {
          for (i = 0; i < topicArray.length; i++) {
            this.finalString = topicArray[i].topicName + ', ' + this.finalString
          }
          this.finalString = this.finalString.substring(0, this.finalString.length - 2);
        }
        return this.finalString
      },
      parseLangPairs(langPairArray) {
        this.finalString = ''
        let i;
        if (langPairArray.length !== 0) {
          for (i = 0; i < langPairArray.length; i++) {
            this.finalString = langPairArray[i].shortPairName + ', ' + this.finalString
          }
          this.finalString = this.finalString.substring(0, this.finalString.length - 2);
        }
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        let timeParsed = moment(time).format("DD.MM.YYYY HH:mm");
        return timeParsed;
      },
      parseDate(date) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        let dateParsed = moment(date).format("DD.MM.YYYY");
        return dateParsed;
      },
      populateFilters(param, paramName) {
        if (paramName === 'dateRange') {
          this.filters.dateRange = param;
        }
        if (paramName === 'agencyId') {
          this.filters.agencyId = param;
        }
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      calculateRevenue() {
        let revenue = this.orders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString();
        return revenue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      },
      dateRangeText() {
        return this.dateRange.join(' ~ ')
      },
      fieldBlock() {
        this.checkboxDate ? this.disabledDate = false : this.disabledDate = true
        this.checkboxAgency ? this.disabledAgency = false : this.disabledAgency = true
        this.checkboxAgency || this.checkboxDate ? this.disabledButton = false : this.disabledButton = true;
        if (!this.checkboxAgency) {
          this.agencyId = '';
          this.filters.agencyId = '';
        }
        if (!this.checkboxDate) {
          this.dateRange = [];
          this.filters.dateRange = [];
        }
      }
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
